@font-face {
  font-family: gt-america-standard-medium;
  src: url("/assets/fonts/Roboto-Medium.ttf") format("TrueType");
}

@font-face {
  font-family: gt-america-standard-regular;
  src: url("/assets/fonts/Roboto-Regular.ttf") format("TrueType");
}

@font-face {
  font-family: gt-america-standard-bold;
  src: url("/assets/fonts/Roboto-Bold.ttf") format("TrueType");
}

@font-face {
  font-family: gt-america-standard-extendedMedium;
  src: url("/assets/fonts/Roboto-Light.ttf") format("TrueType");
}

@font-face {
  font-family: gt-america-standard-light;
  src: url("/assets/fonts/Roboto-Light.ttf") format("TrueType");
}


@font-face {
  font-family: Source-Sans-Pro-ragular;
  src: url("/assets/fonts/Roboto-Regular.ttf") format("TrueType");
}
