@import "../../assets/fonts/fonts.scss";

// Fonts
$font-size: 16px;

$font: "gt-america-standard-regular", "Helvetica Neue", Arial, sans-serif;
$font-bold: "gt-america-standard-bold", "Helvetica Neue", Arial, sans-serif;
$font-light: "gt-america-standard-light", "Helvetica Neue", Arial, sans-serif;
$font-medium: "gt-america-standard-medium", "Helvetica Neue", Arial, sans-serif;
$font-medium-extended: "gt-america-standard-extendedMedium", "Helvetica Neue",
  Arial, sans-serif;

  $sans-font-regular: "Source-Sans-Pro-ragular", "Helvetica Neue", Arial, sans-serif;

body[color-theme="sbi"] {
    // --theme-background:#00a6a8; //#00a6a8 
    // --theme-header:rgb(125,0,129);
    // --theme-button:rgb(125,0,129);
    // --theme-heading:rgb(125,0,129);
    // --theme-ActiveBtn:rgb(125,0,129);
    // --theme-PlusBtn:#01A3A4

    //for testing --Surya 13-Aug-22


    //rgb(125,0,129); --test color 
    //Note - Default values are commented DO NOT REMOVE DEFAULT VALUES
    // --theme-background:rgb(125,0,129); //#00a6a8
    // --theme-header:rgb(125,0,129);
    // --theme-button:rgb(125,0,129);
    // --theme-heading:rgb(125,0,129);
    // --theme-activeBtn:rgb(125,0,129);//#01A3A4    
    // --theme-notActiveBtn:#707070;//#707070
    // --theme-plusBtn:rgb(125,0,129); //#01A3A4  water-guage page plus  defualt color--#509bed;          
    // --theme-text-color:rgb(125,0,129);//#01A3A4;
    // --theme-plusBtnWaterGuage:rgb(125,0,129);  //#509bed;   
    // --theme-minusBtnWaterGuage:rgb(125,0,129);  //#509bed;  
    // --theme-waterCenterPercent:rgb(125,0,129);  //#509bed;  
    // --theme-colorWhite:#FFFFFF;       
    // --theme-boxAdd:1px solid rgb(125,0,129); //#01A3A5;
    // --theme-color:rgb(125,0,129); //#01A3A5;
    // --theme-backgroundLRG: -webkit-linear-gradient(top, rgb(125,0,129) 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
    // --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";    
    // --theme-progressBarWhite:#FFFFFF;
    // --theme-colorBGShades:#f4d2d4; //change this visaly after check the deafulat color;
    // --theme-notActiveBtn:#01A3A4;//#707070



    //#C8222B --test color 
    // --theme-background:#C8222B; //#00a6a8
    // --theme-header:#C8222B;
    // --theme-button:#C8222B;
    // --theme-heading:#C8222B;
    // --theme-activeBtn:#C8222B;//#01A3A4    
    // --theme-plusBtn:#C8222B; //#01A3A4  water-guage page plus  defualt color--#509bed;          
    // --theme-text-color: #C8222B;//#01A3A4;
    // --theme-plusBtnWaterGuage:#C8222B;  //#509bed;   
    // --theme-minusBtnWaterGuage:#C8222B;  //#509bed;  
    // --theme-waterCenterPercent:#C8222B;  //#509bed;  
    // --theme-colorWhite:#FFFFFF;   
    // --theme-boxAdd:1px solid #C8222B; //#01A3A5;
    // --theme-color:#C8222B; //#01A3A5;
    // --theme-backgroundLRG: -webkit-linear-gradient(top, #C8222B 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
    // --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
    // --theme-progressBarWhite:#FFFFFF;
    // --theme-colorBGShades:#f4d2d4; //change this visaly after check the deafulat color;
    // //--theme-notActiveBtn:#01A3A4;//#707070    

    //////Green COLOR /////////DEAFUALT////////
     //#01A3A4 --test color 
    --theme-background:#01A3A4; //#00a6a8
    --theme-header:#01A3A4;
    --theme-button:#01A3A4;
    --theme-heading:#01A3A4;
    --theme-activeBtn:#01A3A4;//#01A3A4       
    --theme-plusBtn:#01A3A4; //#01A3A4  water-guage page plus  defualt color--#509bed;          
    --theme-text-color: #01A3A4;//#01A3A4;
    --theme-plusBtnWaterGuage:#01A3A4;  //#509bed;   
    --theme-minusBtnWaterGuage:#01A3A4;  //#509bed;  
    --theme-waterCenterPercent:#01A3A4;  //#509bed;  
    --theme-colorWhite:#FFFFFF;       
    --theme-boxAdd:1px solid #01A3A4; //#01A3A5;
    --theme-color:#01A3A4; //#01A3A5;
    --theme-backgroundLRG: -webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
    --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
    --theme-progressBarWhite:#FFFFFF;
    --theme-colorBGShades:#b9feff; //change this visaly after check the deafulat color;
    --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS

 
    ///////////////Font////////////
    --theme-headingFont:1.2rem; //merger wirth headeing font 
    //--theme-CardHeadingFont:1.2rem;
    //--theme-seeAll:0.8rem;//merge with .75
    --theme-counters:0.75rem;
    --theme-cardHeading:1rem;
    --theme-text:0.85rem;
    --theme-textHeading:0.9rem;
    --box-shadow:rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    --boxshadow:#00000012 0px 10px 40px;
}

body[color-theme="spc"] {
  --theme-background:rgb(208, 90, 0); //#00a6a8
  --theme-background-1:rgb(208, 90, 0); //#03a3a4 
  --theme-background-2:rgb(208, 90, 0);//#00a6a8
}

body[color-theme="birla"] {

  --theme-background:#01A3A4; //#00a6a8
  --theme-header:#01A3A4;
  --theme-button:#01A3A4;
  --theme-heading:#01A3A4;
  --theme-activeBtn:#01A3A4;//#01A3A4       
  --theme-plusBtn:#01A3A4; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #01A3A4;//#01A3A4;
  --theme-plusBtnWaterGuage:#01A3A4;  //#509bed;   
  --theme-minusBtnWaterGuage:#01A3A4;  //#509bed;  
  --theme-waterCenterPercent:#01A3A4;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #01A3A4; //#01A3A5;
  --theme-color:#01A3A4; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#b9feff; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS

  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem;



  // new vars

  --theme-color:#01A3A4;
  --theme-newHeader:#febf47;
  --theme-newButton:#01A3A4;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:#FEBF47;
  --red:red;
  --theme-newFont:'Rubik', sans-serif;
  --xsmall-font:0.65rem;  //10px
  --small-font:.75rem; //12px
  --regular-font:0.75rem; //14px
  --regularM-font:.75rem; //16px
  --medium-font:.8rem; //18px
  --large-font:1.1rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#B8E6F5;
  --normal-color:#393939;
  --cercle-progress:#B8E6F5;

}

body[color-theme="newmi"] {

  --theme-background:#D9B2A3; //#00a6a8
  --theme-header:#D9B2A3;
  --theme-button:#D9B2A3;
  --theme-heading:#D9B2A3;
  --theme-activeBtn:#D9B2A3;//#01A3A4       
  --theme-plusBtn:#D9B2A3; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #D9B2A3;//#01A3A4;
  --theme-plusBtnWaterGuage:#D9B2A3;  //#509bed;   
  --theme-minusBtnWaterGuage:#D9B2A3;  //#509bed;  
  --theme-waterCenterPercent:#D9B2A3;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #D9B2A3; //#01A3A5;
  --theme-color:#D9B2A3; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #D9B2A3 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#b9feff; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS

  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem;



  // new vars

  --theme-color:#D9B2A3;
  --theme-newHeader:#D9B2A3;
  --theme-newButton:#D9B2A3;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:#D9B2A3;
  --red:red;
  --theme-newFont:'Rubik', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:.75rem; //12px
  --regular-font:0.875rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:1.125rem; //18px
  --large-font:1.5rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#B8E6F5;
  --normal-color:#393939;
  --cercle-progress:#B8E6F5;

}

body[color-theme="beato"] {

  --theme-background:#17a2b8; //#00a6a8
  --theme-header:#17a2b8;
  --theme-button:#17a2b8;
  --theme-heading:#17a2b8;
  --theme-activeBtn:#17a2b8;//#01A3A4       
  --theme-plusBtn:#17a2b8; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #17a2b8;//#01A3A4;
  --theme-plusBtnWaterGuage:#17a2b8;  //#509bed;   
  --theme-minusBtnWaterGuage:#17a2b8;  //#509bed;  
  --theme-waterCenterPercent:#17a2b8;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #17a2b8; //#01A3A5;
  --theme-color:#17a2b8; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #17a2b8 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#F2B70B; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS

  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem;
  // new vars
  --theme-color:#17a2b8;
  --theme-newHeader:#febf47;
  --theme-newButton:#17a2b8;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:#17a2b8;
  --red:red;
  --theme-newFont:'Rubik', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:.75rem; //12px
  --regular-font:0.871rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:.9rem; //18px
  --large-font:1.2rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#F2B70B;
  --normal-color:#393939;
  --cercle-progress:#F2B70B;

}

body[color-theme="infinite"] {

  --theme-background:#D9B2A3; //#00a6a8
  --theme-header:#D9B2A3;
  --theme-button:#D9B2A3;
  --theme-heading:#D9B2A3;
  --theme-activeBtn:#D9B2A3;//#01A3A4       
  --theme-plusBtn:#D9B2A3; //#01A3A4  water-guage page plus  defualt color--#509bed;          
  --theme-text-color: #D9B2A3;//#01A3A4;
  --theme-plusBtnWaterGuage:#D9B2A3;  //#509bed;   
  --theme-minusBtnWaterGuage:#D9B2A3;  //#509bed;  
  --theme-waterCenterPercent:#D9B2A3;  //#509bed;  
  --theme-colorWhite:#FFFFFF;       
  --theme-boxAdd:1px solid #D9B2A3; //#01A3A5;
  --theme-color:#D9B2A3; //#01A3A5;
  --theme-backgroundLRG: -webkit-linear-gradient(top, #D9B2A3 50%, #F6F7FC 50%)!important;  //-webkit-linear-gradient(top, #01A3A4 50%, #F6F7FC 50%)!important;    
  --theme-font:"gt-america-standard-regular", "Helvetica Neue", "Arial", "sans-serif";
  --theme-progressBarWhite:#FFFFFF;
  --theme-colorBGShades:#b9feff; //change this visaly after check the deafulat color;
  --theme-notActiveBtn:#707070;//#707070 //DO NOT CHNAGE THIS

  --theme-headingFont:1.2rem; //merger wirth headeing font 
  --theme-counters:0.75rem;
  --theme-cardHeading:1rem;
  --theme-text:0.85rem;
  --theme-textHeading:0.9rem;



  // new vars

  --theme-color:#D9B2A3;
  --theme-newHeader:#D9B2A3;
  --theme-newButton:#D9B2A3;
  --white:#ffffff;
  --black:#333333;
  --black-light:#676767;
  --lightGrey:#FAFAFA;
  --card-border:#F4F4F4;
  --green:green;
  --yellow:#D9B2A3;
  --red:red;
  --theme-newFont:'Rubik', sans-serif;
  --xsmall-font:0.625rem;  //10px
  --small-font:.75rem; //12px
  --regular-font:0.875rem; //14px
  --regularM-font:1rem; //16px
  --medium-font:1.125rem; //18px
  --large-font:1.5rem;  //24px;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --boxshadow:rgba(0, 0, 0, 0.07) 0px 10px 40px;
  --btnShaddow:rgba(74, 74, 74, 0.2) 0px 12px 30px ;
  --radio-background:#B8E6F5;
  --normal-color:#393939;
  --cercle-progress:#B8E6F5;

}